import { unreachable } from '@workos-inc/standard';
import { DirectoryAttributes } from '../graphql/generated';

export type CoreAttribute = keyof Omit<DirectoryAttributes, '__typename'>;

export const coreAttributeToLabel = (attribute: CoreAttribute): string => {
  switch (attribute) {
    case 'externalId':
      return 'Unique ID';
    case 'username':
      return 'Username';
    case 'emails':
      return 'Email';
    case 'firstName':
      return 'First Name';
    case 'lastName':
      return 'Last Name';
    case 'groupName':
      return 'Group Name';
    default:
      return unreachable(attribute);
  }
};
