import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { Ol } from '../../typography';
import { MetadataXmlStep } from './saml/metadata-xml-step';

export const OneLoginCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      In the OneLogin platform, go to the Admin Dashboard and select the
      "Applications" tab in the navigation bar.
    </Text>

    <Img
      className="my-8"
      height={1734}
      src="/images/3b7bc5e9-0486-4e22-96b8-c080869239b8.png"
      width={2816}
    />

    <Text>
      Select your application from the list of applications. If creating a new
      application, select the "SAML Custom Connector (Advanced)" type and
      continue.
    </Text>

    <Img
      className="my-8"
      height={638}
      src="/images/6d83bddd-b75c-4ef0-ac9d-a3c8c681d3c0.png"
      width={1464}
    />

    <Confirm
      label="I’ve created a SAML Enterprise Application"
      onClick={handleNextStep}
    />
  </>
);

export const OneLoginBasicConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the “Configuration” tab from the left sidebar.</Text>

    <Img
      className="my-8"
      height={2002}
      src="/images/fdbf90cc-fec5-4fe3-a0f4-cc6c2a06f6eb.png"
      width={2816}
    />

    <CopyInput
      label="Copy this ACS URL Validator"
      value={
        connection?.saml_acs_url
          ? `^${connection?.saml_acs_url.replace(/\//g, '\\/')}$`
          : undefined
      }
    />

    <Text>Submit the “ACS URL Validator” in the Application details.</Text>

    <Img
      className="my-8"
      height={2002}
      src="/images/04124f22-68a6-4a8e-821f-92a81e690d62.png"
      width={2816}
    />

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>Submit the “ACS URL” in the Application details.</Text>

    <Img
      className="my-8"
      height={2002}
      src="/images/ba24f597-f52f-4651-8b7c-f7366ccba63f.png"
      width={2816}
    />

    <Text>
      Enter the Login URL that your SP uses to initiate the login in the Login
      URL field.
    </Text>

    <Img
      className="my-8"
      height={2002}
      src="/images/72d7b813-6b62-4823-900d-4691f98122ec.png"
      width={2816}
    />

    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={handleNextStep}
    />
  </>
);

export const OneLoginAdvancedConfiguration: FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      Continue scrolling down the Application Details to “SAML Initiator”.
    </Text>

    <Text>Select “Service Provider” under “SAML Initiator”.</Text>

    <Img
      className="my-8"
      height={1222}
      src="/images/820d289b-d982-45ad-8788-5992b33bffe9.png"
      width={2816}
    />

    <Text>Select “Assertion” under “SAML signature element”.</Text>

    <Img
      className="my-8"
      height={1222}
      src="/images/5802afac-d1ce-43e1-b7fa-e76c8f171f21.png"
      width={2816}
    />

    <Confirm
      label="I’ve selected “Assertion” as the signature element."
      onClick={handleNextStep}
    />
  </>
);

export const OneLoginConfigureParameters: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the “Parameters” tab from the left sidebar.</Text>

    <Img
      className="my-8"
      height={1558}
      src="/images/4c4b30b9-015f-4e97-acb2-f67bd116c077.png"
      width={2816}
    />

    <Text>
      {`Fill in the following parameters and check the "Include in SAML
            assertion" flag for each pair.`}
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      className="my-8"
      height={1558}
      src="/images/c0f82981-efe8-4da5-a327-367454fc2b69.png"
      width={2816}
    />

    <Confirm
      label="I’ve configured the Application’s parameters."
      onClick={handleNextStep}
    />
  </>
);

export const OneLoginUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://app.onelogin.com/saml/metadata/..."
    idpSsoUrlPlaceholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
  >
    <Ol className="my-4">
      <li>Click the “SSO” tab from the left sidebar.</li>

      <li>
        Click the "More Actions" dropdown and then select "SAML Metadata".
      </li>

      <li>An XML file will download.</li>
    </Ol>

    <Img
      className="my-8"
      height={779}
      src="/images/55c7cbb9-fbda-4ab9-932e-25a551ee16c5.png"
      width={1408}
    />

    <Text>Upload the XML Metadata file below.</Text>
  </MetadataXmlStep>
);
