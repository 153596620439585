import { InputGroup, Text } from '@workos-inc/component-library';
import React from 'react';
import { DirectoryStepProps } from '../../../interfaces/step-props';
import { Card } from '../../card';
import { Confirm } from '../../confirm';
import { Form } from '../../form';
import { Img } from '../../image';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsHibob: ArrayStepsConfig = {
  providerLabel: 'Hibob',
  type: 'Array',
  steps: [
    {
      name: 'Create a Service User in Hibob',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Log in to your Hibob instance as an admin user and navigate to
            settings, then select the "Integrations" tab.
          </Text>

          <Img
            className="my-8"
            height={497}
            src="/images/db6655f5-0d41-4200-8480-854e92d6ccf0.png"
            width={1050}
          />

          <Text>
            There will be a tile called Service Users, click on that then, press
            on the New Service user Button.
          </Text>

          <Text>
            You will be prompted to enter a Name and Display Name for the
            service user. Give the Service User a descriptive name and display
            name such as <strong>{appName} SCIM User</strong> and select "Done".
          </Text>
          <Img
            className="my-8"
            height={668}
            src="/images/898e5fa5-264b-4990-8224-e291ab253dde.png"
            width={1051}
          />

          <Confirm
            label="I've created a Hibob service user with Name and Display Name."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload Hibob Service User ID and Token',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange: handleInputChange,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            An <strong> ID </strong> and <strong> Token </strong>
            will appear - make sure to save these somewhere secure should you
            need to access them again.
          </Text>

          <Img
            className="my-8"
            height={717}
            src="/images/6f8e2cec-3531-4f7e-8a1e-63df6ec9914e.png"
            width={1019}
          />
          <Text>Upload the Service User ID and Token below:</Text>

          <Card>
            <Form
              disabled={
                !directory?.bob_service_user_id || !directory?.bob_api_token
              }
              isLoading={isLoading}
              isUpdate={
                !!(errors?.bob_service_user_id || errors?.bob_api_token)
              }
              onSubmit={handleNextStep}
            >
              <InputGroup
                autoFocus
                error={errors?.bob_service_user_id?.message}
                id="bob_service_user_id"
                label="1. Service User ID"
                name="bob_service_user_id"
                onChange={handleInputChange}
                placeholder="SERVICE-387"
                value={directory?.bob_service_user_id ?? undefined}
              />

              <InputGroup
                error={errors?.bob_api_token?.message}
                id="bob_api_token"
                label="2. Service User API Token"
                name="bob_api_token"
                onChange={handleInputChange}
                placeholder=""
                type="password"
                value={directory?.bob_api_token ?? undefined}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: MapAttributesStep,
    },
  ],
};
