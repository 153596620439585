import { Text } from '@workos-inc/component-library';
import React, { useContext } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { StoreContext } from '../../store-provider';
import { MetadataUrlStep } from './saml/metadata-url-step';

export const MiniOrangeSAMLIntegration: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep: handleNextStep }) => {
  const {
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <>
      <Text>
        From the miniOrange / Xecurify dashboard, select "Apps" on the left side
        navigation. Then, select "Add Application".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/7b04f89a-e0cb-489b-84b8-4fb650011d88.png"
        width={2730}
      />

      <Text>Under "SAML/WS-FED", select "Create App".</Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/12747857-6d59-4826-b7b7-3c4db30104ad.png"
        width={2730}
      />

      <Text>
        Search for "custom" in the search box and select "Custom SAML App".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/29aa7fa0-f4d8-4004-8782-2d15728824dd.png"
        width={2730}
      />

      <Text>
        Under the "Basic Settings" tab of the SAML app, select "Import SP
        Metadata".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/1cd7ab06-e8ec-4290-8b34-3e040d175c72.png"
        width={2730}
      />

      <Text>
        Give the SAML app a descriptive name under "App Name". Under "SP
        Metadata", select "URL" and input the SP Metadata URL below. Then, hit
        "Import".
      </Text>

      <CopyInput
        label="Copy this URL"
        value={connection?.saml_sp_metadata_url}
      />

      <Img
        className="my-8"
        height={1606}
        src="/images/0955c954-7107-4dbe-9f0b-3b7cb5e12411.png"
        width={2730}
      />

      <Text>
        Make sure that you have the "Sign Assertion" field toggled on.
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/8787363b-0589-4b6b-8083-abcaa61165dc.png"
        width={2730}
      />

      <Text>Select "Next".</Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/d81dcacd-8a7c-4053-bbe8-d85d6d6b4968.png"
        width={2730}
      />

      <Confirm
        label="I've configured the Single sign on URL and Audience URI."
        onClick={handleNextStep}
      />
    </>
  );
};

export const MiniOrangeAttributeStatements: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep: handleNextStep }) => {
  const {
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <>
      <Text>
        Under the "Attribute Mapping" section of the SAML app, select "Add
        Attribute".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/a00c7b90-c656-4b0e-9fc8-f725100a52d4.png"
        width={2730}
      />

      <Text>
        Map the following four attributes as shown below, and the select "Save".
      </Text>

      <ConnectionAttributeMapping connectionType={connection?.type} />

      <Img
        className="my-8"
        height={1606}
        src="/images/275323ad-f50e-4aa9-acea-206f4679cc73.png"
        width={2730}
      />

      <Confirm
        label="I've finished configuring the Attribute Statements
        and clicked “Save”."
        onClick={handleNextStep}
      />
    </>
  );
};

export const MiniOrangeIdentityProviderMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://login.xecurify.com/moas/..."
    idpSsoUrlPlaceholder="https://login.xecurify.com/moas/idp/samlsso/..."
    metadataUrlPlaceholder="https://login.xecurify.com/moas/metadata/saml/..."
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Back on the "Apps" tab of the miniOrange Dashboard, click "Select" next to
      the app you've created. From the dropdown, select "Metadata".
    </Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/86d9be6b-afa8-49c7-9c97-3226ccc6e286.png"
      width={2730}
    />

    <Text>
      Under the "INFORMATION REQUIRED TO SET MINIORANGE AS IDP" section, click
      the icon next to "Metadata URL" to copy it to your clipboard.
    </Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/502a85fb-e6d0-4a89-ad49-280c2ee8d4ab.png"
      width={2730}
    />

    <Text>
      Input the IdP Metadata URL below and select "Continue to Next Step".
    </Text>
  </MetadataUrlStep>
);
