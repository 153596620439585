import {
  ProviderAdfs,
  ProviderAdp,
  ProviderAuth0,
  ProviderAzure,
  ProviderAzureAd,
  ProviderBambooHR,
  ProviderBreatheHr,
  ProviderCas,
  ProviderClassLink,
  ProviderCloudflare,
  ProviderCyberArk,
  ProviderDuo,
  ProviderFourthHr,
  ProviderGoogle,
  ProviderGoogleSaml,
  ProviderGusto,
  ProviderHibob,
  ProviderJumpCloud,
  ProviderKeycloak,
  ProviderLastPass,
  ProviderMiniOrange,
  ProviderNetIq,
  ProviderOkta,
  ProviderOnelogin,
  ProviderOpenId,
  ProviderOracle,
  ProviderOther,
  ProviderPeopleHr,
  ProviderPingFederate,
  ProviderPingOne,
  ProviderRippling,
  ProviderSalesforce,
  ProviderSaml,
  ProviderScim,
  ProviderSimpleSaml,
  ProviderVMWare,
  ProviderWorkday,
} from '@workos-inc/component-library';
import React, { ReactElement } from 'react';
import { ConnectionType, DirectoryType } from '../../graphql/generated';

export interface ProviderLogoProps {
  provider: ConnectionType | DirectoryType;
  width?: string;
  className?: string;
  height?: string;
}

export const ProviderLogo = ({
  provider,
  ...props
}: ProviderLogoProps): ReactElement | null => {
  switch (provider) {
    case ConnectionType.Adfssaml:
      return <ProviderAdfs {...props} />;
    case ConnectionType.AdpOidc:
      return <ProviderAdp {...props} />;
    case ConnectionType.Auth0Saml:
      return <ProviderAuth0 {...props} />;
    case ConnectionType.AzureSaml:
      return <ProviderAzure {...props} />;
    case ConnectionType.CasSaml:
      return <ProviderCas {...props} />;
    case ConnectionType.CloudflareSaml:
      return <ProviderCloudflare {...props} />;
    case ConnectionType.ClassLinkSaml:
      return <ProviderClassLink {...props} />;
    case ConnectionType.CyberArkSaml:
    case DirectoryType.CyberArkScimV2_0:
      return <ProviderCyberArk {...props} />;
    case ConnectionType.DuoSaml:
      return <ProviderDuo {...props} />;
    case ConnectionType.GoogleOAuth:
      return <ProviderGoogle {...props} />;
    case ConnectionType.GoogleSaml:
      return <ProviderGoogleSaml {...props} />;
    case ConnectionType.JumpCloudSaml:
      return <ProviderJumpCloud {...props} />;
    case ConnectionType.KeycloakSaml:
      return <ProviderKeycloak {...props} />;
    case ConnectionType.NetIqSaml:
      return <ProviderNetIq {...props} />;
    case ConnectionType.OktaSaml:
      return <ProviderOkta {...props} />;
    case ConnectionType.OneLoginSaml:
      return <ProviderOnelogin {...props} />;
    case ConnectionType.OracleSaml:
      return <ProviderOracle {...props} />;
    case ConnectionType.GenericOidc:
      return <ProviderOpenId {...props} />;
    case ConnectionType.GenericSaml:
      return <ProviderSaml {...props} />;
    case ConnectionType.LastPassSaml:
      return <ProviderLastPass {...props} />;
    case ConnectionType.MiniOrangeSaml:
      return <ProviderMiniOrange {...props} />;
    case ConnectionType.PingFederateSaml:
      return <ProviderPingFederate {...props} />;
    case DirectoryType.PingFederateScimV2_0:
      return <ProviderPingFederate {...props} />;
    case ConnectionType.PingOneSaml:
      return <ProviderPingOne {...props} />;
    case ConnectionType.RipplingSaml:
      return <ProviderRippling {...props} />;
    case ConnectionType.SalesforceSaml:
      return <ProviderSalesforce {...props} />;
    case ConnectionType.SimpleSamlPhpSaml:
      return <ProviderSimpleSaml {...props} />;
    case ConnectionType.VMwareSaml:
      return <ProviderVMWare {...props} />;
    case DirectoryType.AzureScimv2_0:
      return <ProviderAzureAd {...props} />;
    case DirectoryType.BambooHr:
      return <ProviderBambooHR {...props} />;
    case DirectoryType.BreatheHr:
      return <ProviderBreatheHr {...props} />;
    case DirectoryType.FourthHr:
      return <ProviderFourthHr {...props} />;
    case DirectoryType.GoogleWorkspace:
      return <ProviderGoogle {...props} />;
    case DirectoryType.Gusto:
      return <ProviderGusto {...props} />;
    case DirectoryType.Hibob:
      return <ProviderHibob {...props} />;
    case DirectoryType.JumpCloudScimV2_0:
      return <ProviderJumpCloud {...props} />;
    case DirectoryType.OktaScimv1_1:
    case DirectoryType.OktaScimv2_0:
      return <ProviderOkta {...props} />;
    case DirectoryType.OneLoginScimV2_0:
      return <ProviderOnelogin {...props} />;
    case DirectoryType.PeopleHr:
      return <ProviderPeopleHr {...props} />;
    case DirectoryType.Rippling:
      return <ProviderRippling {...props} />;
    case DirectoryType.GenericScimv1_1:
      return <ProviderScim {...props} />;
    case DirectoryType.GenericScimv2_0:
      return <ProviderScim {...props} />;
    case DirectoryType.Workday:
      return <ProviderWorkday {...props} />;
    default:
      return <ProviderOther className={props.className} />;
  }
};
