import { datadogRum } from '@datadog/browser-rum';
import { sendBugsnagError } from '../bugsnag';

export const initDataDogRum = () => {
  try {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID as string,
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
      env: process.env.NEXT_PUBLIC_DD_ENVIRONMENT,
      sampleRate: 100,
      service: process.env.NEXT_PUBLIC_DD_SERVICE_NAME,
      silentMultipleInit: true,
      site: 'datadoghq.com',
      trackInteractions: true,
    });
  } catch (error) {
    if (error instanceof Error) {
      sendBugsnagError(error);
    }
  }
};
