import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataUrlStep } from './saml/metadata-url-step';

export const VMWareCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>In your Workspace ONE Catalog, click "New".</Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/812c5a3b-f3a1-4944-abd8-6749144cd691.png"
      width={2692}
    />

    <Text>Provide a Name that describes {appName}, then click "Next".</Text>

    <Confirm
      label="I’ve created a new SaaS Application"
      onClick={handleNextStep}
    />
  </>
);

export const VMWareBasicConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the "Configuration" tab from the left sidebar.</Text>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Text>Submit the "URL/XML".</Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/12b5df2a-1e8a-4f95-ba69-ff7d3b09e4e5.png"
      width={2692}
    />
    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={handleNextStep}
    />
  </>
);

export const VMWareAdvancedConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Continue scrolling and expand "Advanced Properties".</Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/ce886446-89ee-4649-8d67-ee5dc3d09106.png"
      width={2692}
    />

    <Text>Enable "Sign Assertion" and "Include Assertion Signature".</Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/ae25e3dd-4167-4ed4-9838-48e2ede08d4c.png"
      width={2692}
    />

    <Confirm
      label='I’ve enabled "Sign Assertion" and "Include Assertion Signature"'
      onClick={handleNextStep}
    />
  </>
);

export const VMWareConfigureAttributeMap: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Continue scrolling until "Custom Attribute Mapping".</Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/aedeb103-65d9-4cae-af63-39cc4a6b0a33.png"
      width={2692}
    />

    <Text>Fill in the following attribute mappings and select "Next".</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Text>
      NOTE: Some VMware configurations use user.ExternalId instead of
      user.objectGUID. In this case, you would map the id attribute to
      user.ExternalId.
    </Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/0c3bbf1b-6737-48b2-aced-5589a9afcac8.png"
      width={2692}
    />
    <Confirm
      label="I’ve configured the Attribute Map"
      onClick={handleNextStep}
    />
  </>
);

export const VMWareUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://tenant.vmwareidentity.com/SAAS/API/1.0/GET/metadata/idp.xml..."
    idpSsoUrlPlaceholder="https://foocorp.workspaceoneaccess.com..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      After saving your SaaS Application, click "Settings" and then "SAML
      Metadata". Click on "Copy URL" next to "Identity Provider (IdP) metadata"
      and paste it below.
    </Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/8456f5b8-f7d7-4809-8698-055a9273bf80.png"
      width={2692}
    />
  </MetadataUrlStep>
);
