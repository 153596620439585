import { InputGroup, Text } from '@workos-inc/component-library';
import React from 'react';
import { DirectoryStepProps } from '../../../interfaces/step-props';
import { Card } from '../../card';
import { Confirm } from '../../confirm';
import { Form } from '../../form';
import { Img } from '../../image';
import { Link } from '../../link';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsPeopleHr: ArrayStepsConfig = {
  providerLabel: 'Access People HR',
  type: 'Array',
  steps: [
    {
      name: 'Generate API Key in Access People HR',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            If you are an admin of your Access People HR instance, log in and
            select the "Settings" page from the side bar menu. Then, select
            "API" from the Settings side bar menu. On the API Key Management
            page, select the plus sign to add a new API Key.
          </Text>

          <Img
            className="my-8"
            height={1606}
            src="/images/66251774-b635-418b-9521-7a62836a3ee5.png"
            width={2728}
          />

          <Text>
            In the API Key Generator, give the API Key a descriptive name. Under
            "Application", select "Employee".
          </Text>

          <Img
            className="my-8"
            height={1606}
            src="/images/0a92af08-8499-41a7-9fda-2c18da21f6ce.png"
            width={2728}
          />

          <Text>
            On the Select Permissions page, check only "Get All Employee Detail"
            and then select "Save".
          </Text>

          <Img
            className="my-8"
            height={1606}
            src="/images/a286675a-81b3-48f0-9c92-61595ad7e080.png"
            width={2728}
          />

          <Text>On the API Key Generator page, select "Save".</Text>

          <Img
            className="my-8"
            height={1606}
            src="/images/49cfbc5f-4a20-475c-98fb-e1454dd32ce0.png"
            width={2728}
          />
          <Text>
            Copy and save the API key - you will input it in the next step.
          </Text>

          <Img
            className="my-8"
            height={1606}
            src="/images/99596b83-7124-448b-a7cd-41e342132fcb.png"
            width={2728}
          />

          <Confirm
            label="I've generated my Access People HR API key."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload Access People HR API Key',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange: handleInputChange,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>Upload the API key you generated in your last step.</Text>

          <Card>
            <Form
              disabled={!directory?.peopleHrApiKey}
              isLoading={isLoading}
              isUpdate={!!errors?.peopleHrApiKey}
              onSubmit={handleNextStep}
            >
              <InputGroup
                autoFocus
                error={errors?.peopleHrApiKey?.message}
                id="peopleHrApiKey"
                label="1. Access People HR API Key"
                name="peopleHrApiKey"
                onChange={handleInputChange}
                placeholder=""
                type="password"
                value={directory?.peopleHrApiKey ?? undefined}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            The full list of available attributes from Access People HR can be
            found{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://apidocs.peoplehr.com/#tag/Employee%2Fpaths%2F~1Employee%20%20-%20%20GetAllEmployeeDetail%2Fpost'
              }
            >
              in their API documentation.{' '}
            </Link>
            You'll need to input your generated API key to see the above
            documentation.
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
  ],
};
