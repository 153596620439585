import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataXmlStep } from './saml/metadata-xml-step';

export const LastPassCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      From the LastPass admin console, select "Applications" on the top
      navigation. Then select "SSO apps" from the left side navigation. Click on
      the "Add app" button.
    </Text>

    <Img
      height={1360}
      src="/images/e6cc5fbc-e802-4cd4-b3d6-786c31ee7db2.png"
      width={2486}
    />

    <Text>In the modal that pops up, click on "Add an unlisted app".</Text>

    <Img
      height={1258}
      src="/images/fad2dc44-73a1-4b98-b8bb-ee02e7293dfd.png"
      width={2158}
    />

    <Text>
      Give your SAML App a descriptive name such as {appName} and select
      "Continue".
    </Text>

    <Img
      height={1258}
      src="/images/839575b4-8b1c-45e5-ab10-8fcc509fc1be.png"
      width={2158}
    />

    <Confirm
      label="I’ve finished naming the SAML Application."
      onClick={handleNextStep}
    />
  </>
);

export const LastPassSubmitUrlEntityId: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Under the "Set up LastPass" section of the "Configure app" modal, input
      the ACS URL below under "ACS". Then click on "Advanced Settings".
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Img
      height={1392}
      src="/images/01e69cb0-8c93-474f-ad15-6cde111ac699.png"
      width={2278}
    />

    <Text>
      Under "Entity ID", input the SP Entity ID below. Next, under "SAML
      signature method", select "SHA256".
    </Text>

    <CopyInput
      label="Copy this SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Img
      height={1830}
      src="/images/4b80975b-c0bb-4efd-80dd-3a3c52836ec5.png"
      width={2584}
    />

    <Text>
      Under "Signing and encryption", ensure that you have at least selected
      "Sign assertion". Then, click on "Add SAML attribute".
    </Text>

    <Img
      height={1666}
      src="/images/0e2a022f-444b-468b-91ad-cb2078142425.png"
      width={2424}
    />
    <Confirm
      label="I’ve configured the Assertion Consumer Service (ACS) URL and
          Entity ID."
      onClick={handleNextStep}
    />
  </>
);

export const LastPassConfigureAttributeStatements: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      Map the following four attributes as shown below, and select "Save {'&'}{' '}
      assign users".
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      height={2042}
      src="/images/3e75c892-a7cc-4167-9e45-c046232c1231.png"
      width={2740}
    />

    <Confirm
      label="I’ve finished configuring the Attribute Statements
          and clicked “Save & assign users“."
      onClick={handleNextStep}
    />
  </>
);

export const LastPassAddUsers: FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      On the "Users, groups {'&'} roles" page, click on "Assign users, groups{' '}
      {'&'} roles".
    </Text>

    <Img
      height={1790}
      src="/images/cec59ee3-85f1-45cd-8ed5-03dfef194de9.png"
      width={2430}
    />

    <Text>
      Search and select any users or groups that you would like to provision to
      this SAML app. Then, click "Assign".
    </Text>

    <Img
      height={1790}
      src="/images/fc3fd859-1092-45a8-922f-38b4619863d8.png"
      width={2430}
    />

    <Text>Click on "Save {'&'} continue".</Text>

    <Img
      height={1426}
      src="/images/4b775f48-9045-4122-8c9c-4770370edd4b.png"
      width={2298}
    />

    <Confirm
      label="I've completed adding users and clicked “Save & continue”."
      onClick={handleNextStep}
    />
  </>
);

export const LastPassUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://identity.lastpass.com/..."
    idpSsoUrlPlaceholder="https://identity.lastpass.com/SAML/..."
  >
    <Text as="h2" size="large" weight="medium">
      Metadata File Configuration
    </Text>

    <Text>
      Back on the "SSO apps" tab of the LastPass admin console, select the SAML
      app that you just created.
    </Text>

    <Img
      height={1516}
      src="/images/99a9a771-02bc-4817-b576-414bafa2d6f2.png"
      width={2622}
    />

    <Text>
      On the "Configure app" modal, click on "Expand" to the right of "Set up
      app".
    </Text>

    <Img
      height={1512}
      src="/images/e717af8d-f5aa-4629-ae38-d5d0376d57dd.png"
      width={2270}
    />

    <Text>
      At the bottom of the "Set up app" section, click on "Download metadata
      (XML)". Upload this file below.
    </Text>

    <Img
      height={1726}
      src="/images/bbd5f4bc-8386-4650-9559-f072b265d71b.png"
      width={2604}
    />
  </MetadataXmlStep>
);
