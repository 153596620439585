import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataUrlStep } from './saml/metadata-url-step';

export const AzureCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Select "Enterprise applications" from your Azure AD dashboard.</Text>

    <Img
      className="my-8"
      height={1218}
      src="/images/37f3b3d2-fcdb-4a5f-8d93-970ec0239446.png"
      width={2106}
    />

    <Text>Click "New application" and continue.</Text>

    <Img
      className="my-8"
      height={1196}
      src="/images/3c26fec7-f77a-4757-8a04-6998ff7beb26.png"
      width={2066}
    />

    <Text>
      Select "Create your own application", then enter an App name that
      describes {appName}. Under "What are you looking to do with your
      application?", select "Integrate any other application you don't find in
      the gallery (Non-gallery)", then select "Create".
    </Text>

    <Img
      className="my-8"
      height={1196}
      src="/images/9f998b1b-87b4-4f1d-a50c-0a17f8bb3f98.png"
      width={2066}
    />

    <Text>
      Next, select "Single Sign On" from the "Manage" section in the left
      sidebar navigation menu, and then "SAML".
    </Text>

    <Img
      className="my-8"
      height={1546}
      src="/images/f90c0d1a-ed45-4bee-b77f-dcf37a63a6bd.png"
      width={2072}
    />

    <Confirm
      label="I’ve created a SAML Enterprise Application"
      onClick={handleNextStep}
    />
  </>
);

export const AzureSamlConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the Edit icon in the top right of the first step.</Text>

    <Img
      className="my-8"
      height={1546}
      src="/images/e7a4397c-8120-43c6-9b66-e6a2251cd5bd.png"
      width={2208}
    />

    <CopyInput
      label="Copy this Identifier"
      value={connection?.saml_entity_id}
    />

    <CopyInput label="Copy this Reply URL" value={connection?.saml_acs_url} />

    <Text>
      Submit the Identifier and the Reply URL in the Basic SAML Configuration.
    </Text>

    <Img
      className="my-8"
      height={1268}
      src="/images/43e8aed9-fdfa-421a-8db7-94a051bcbfa4.png"
      width={2150}
    />

    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={handleNextStep}
    />
  </>
);

export const AzureUserAttributes: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the Edit icon in the top right of the second step.</Text>

    <Img
      className="my-8"
      height={1268}
      src="/images/6155597f-a4b8-4a3c-a7f3-a6e8d4e0865d.png"
      width={2150}
    />

    <Text>Fill in the following Attribute Statements and select "Next":</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      className="my-8"
      height={1242}
      src="/images/f41b5a7c-1842-4567-9f46-85caa7309176.png"
      width={1774}
    />

    <Confirm
      label="I’ve configured the User Attributes & Claims."
      onClick={handleNextStep}
    />
  </>
);

export const AzureAssignPeople: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      In order for your users and groups of users to be synced to {appName} you
      will need to assign them to your Azure AD SAML Application. Select "Users
      and groups" from the "Manage" section of the navigation menu.
    </Text>

    <Img
      className="my-8"
      height={1292}
      src="/images/7fe69c75-311e-409b-905a-dbd6b6087394.png"
      width={2230}
    />

    <Text>Select "Add user/group" from the top menu.</Text>

    <Img
      className="my-8"
      height={1260}
      src="/images/817e4d3d-7470-4e20-b9f1-af74ea8adc73.png"
      width={2222}
    />

    <Text>
      Select "None selected" under the "Users and Groups". In the menu, select
      the users and groups of users that you want to add to the SAML
      application, and click "Select".
    </Text>

    <Img
      className="my-8"
      height={1280}
      src="/images/54841bd3-33b9-451b-a5ab-dd94c0040034.png"
      width={2312}
    />

    <Text>
      Select "Assign" to add the selected users and groups of users to your SAML
      application.
    </Text>

    <Img
      className="my-8"
      height={1198}
      src="/images/5420b145-6086-4141-b096-9ae9f6ea8529.png"
      width={1696}
    />

    <Confirm label="I’ve completed my assignments." onClick={handleNextStep} />
  </>
);

export const AzureMetadataUpload: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://sts.windows.net/123-abc/"
    idpSsoUrlPlaceholder="https://login.microsoftonline.com/123abc/saml2"
    metadataUrlPlaceholder="https://login.microsoftonline.com/123-abc/..."
  >
    <Text>
      Navigate down to Section 3 of the "Single Sign On" page, to "SAML Signing
      Certificate". Copy the URL provided in "App Federation Metadata URL".
    </Text>

    <Img
      className="my-8"
      height={1392}
      src="/images/c4ee0b27-ddd7-4aab-96c0-ced1019b4cd7.png"
      width={2176}
    />

    <Text>Provide the Metadata URL you copied below.</Text>
  </MetadataUrlStep>
);
