import { resolveStepsConfig as resolveDsyncStepsConfig } from '../components/dsync/steps/steps-config';
import {
  stepsConfig as ssoConfig,
  stepsConfig,
} from '../components/sso/steps/steps-config';
import { DirectoryCustomAttributeFragment as DirectoryCustomAttribute } from '../graphql/generated';
import { SupportedConnectionType } from '../interfaces/supported-connection-type';
import { SupportedDirectoryType } from '../interfaces/supported-directory-type';

export const getTitle = (
  appName: string,
  hidePoweredByWorkos: boolean,
  directoryCustomAttributes: DirectoryCustomAttribute[],
  pathname: string,
  provider?: SupportedConnectionType | SupportedDirectoryType,
): string => {
  const isConnectionRoute = pathname.includes('/connection');
  const isDSync = pathname.includes('/dsync');
  const isDirectoryOverview = pathname.includes('/directory');

  if (isConnectionRoute) {
    if (hidePoweredByWorkos) {
      return `${appName} Admin Portal`;
    }
    return '';
  }
  if (isDSync) {
    if (isDirectoryOverview) {
      return '';
    }
    if (provider) {
      return `Configure Directory Sync with ${
        resolveDsyncStepsConfig({
          directoryCustomAttributes,
        })[provider as SupportedDirectoryType].providerLabel
      }`;
    }
    return 'Configure Directory Sync';
  }
  if (provider && Object.keys(stepsConfig).includes(provider)) {
    return `Configure SSO with ${
      ssoConfig[provider as SupportedConnectionType].providerLabel
    }`;
  }
  return 'Configure Single Sign-On';
};
