import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { Ol } from '../../typography';
import { MetadataXmlStep } from './saml/metadata-xml-step';

export const JumpCloudCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      From the JumpCloud Admin Console, select “SSO” on the navigation to the
      left. Then select the green “+” sign to create a new application.
    </Text>

    <Img
      className="my-8"
      height={1394}
      src="/images/1e1363a9-eaf4-4d42-b398-f1f659a1dd01.png"
      width={2214}
    />

    <Text>In the subsequent pop-up window, select “Custom SAML App”.</Text>

    <Img
      className="my-8"
      height={1438}
      src="/images/18cbf074-e1e5-43df-9d97-e88bc671009c.png"
      width={2494}
    />

    <Text>
      Under "Display Label", give it a descriptive name to easily identify it
      later such as {appName}-{connection?.name}.
    </Text>

    <Img
      className="my-8"
      height={1570}
      src="/images/34143c1f-f8de-4246-b3bc-ea87a97abc42.png"
      width={2546}
    />

    <Confirm
      label="I’ve finished naming the SAML Application"
      onClick={handleNextStep}
    />
  </>
);

export const JumpCloudSubmitUrlEntityId: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Copy the Entity ID into the "IdP Entity ID" and "SP Entity ID" fields.
    </Text>

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>Copy the ACS URL into the "ACS URL" field.</Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Img
      className="my-8"
      height={716}
      src="/images/893862e8-e577-4407-b76f-494e4ab8bc64.png"
      width={1167}
    />

    <Text>IMPORTANT: Be sure to check the “Sign Assertion” box.</Text>

    <Img
      className="my-8"
      height={1226}
      src="/images/e4cca1f9-3f24-45d6-80f7-3ef318eb4ba2.png"
      width={2120}
    />

    <Confirm
      label="I’ve configured the Assertion Consumer Service (ACS) URL and
          Entity ID."
      onClick={handleNextStep}
    />
  </>
);

export const JumpCloudConfigureAttributeStatements: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      In the “User Attributes” section, click on the "add attribute" button to
      begin adding new user attributes. Add in the below attribute statements.
    </Text>

    <Img
      className="my-8"
      height={1306}
      src="/images/47100088-c7b5-4352-96e1-9709f7808b6b.png"
      width={2126}
    />

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Text>
      Once you’ve finished, select “activate” and then click on the “continue”
      button on the subsequent pop-up window.
    </Text>

    <Confirm
      label="I’ve finished configuring the Attribute Statements
          and clicked “activate” and “continue“."
      onClick={handleNextStep}
    />
  </>
);

export const JumpCloudUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://sso.jumpcloud.com/..."
  >
    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Ol className="my-4">
      <li>Click on the “SSO” tab of the SAML app you just created.</li>

      <li>Be sure to check "Declare Redirect Endpoint".</li>

      <Img
        className="my-8"
        height={1226}
        src="/images/3a24425c-dd94-4b86-8b3a-f476b6f55665.png"
        width={2120}
      />

      <li>Click the "Export Metadata" button under "JumpCloud Metadata".</li>

      <Img
        className="my-8"
        height={1226}
        src="/images/abd1a6ff-ec7b-427b-b7d5-0813af0107ee.png"
        width={2120}
      />

      <li>A file will be downloaded. Upload it below.</li>
    </Ol>
  </MetadataXmlStep>
);
