import { Text } from '@workos-inc/component-library';
import React from 'react';
import { DirectoryStepProps } from '../../../interfaces/step-props';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsAzure: ArrayStepsConfig = {
  providerLabel: 'Azure SCIM v2',
  type: 'Array',
  steps: [
    {
      name: 'Create Azure AD SCIM Application',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            From your Enterprise Application dashboard, select "New
            Application".
          </Text>

          <Img
            className="my-8"
            height={1440}
            src="/images/8b47e2e9-49f1-40c0-8821-a2911804d39f.png"
            width={2702}
          />

          <Text>Select "Create your own application" and continue.</Text>

          <Img
            className="my-8"
            height={1616}
            src="/images/008b7662-dfee-4088-a956-c4c39b46557b.png"
            width={2680}
          />

          <Text>
            Give your application a descriptive name such as{' '}
            <strong>{appName}</strong>, and select the "Integrate any other
            application you don't find in the gallery (Non-gallery)" option,
            then click "Create".
          </Text>

          <Img
            className="my-8"
            height={1616}
            src="/images/27fbdf37-dd82-4c74-85a9-74be472ff623.png"
            width={2680}
          />

          <Confirm
            label="I’ve created my Azure AD SCIM application."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure the Azure AD SCIM Admin Credentials',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Select "Provisioning" from the "Manage" section found in the
            navigation menu of the SCIM application.
          </Text>

          <Img
            className="my-8"
            height={1272}
            src="/images/c7cb3d02-a99c-4ded-bddf-ff77db2b8ced.png"
            width={2118}
          />

          <Text>Click the "Get Started" button.</Text>

          <Img
            className="my-8"
            height={1272}
            src="/images/8c673e8c-d2ad-4bc6-a1d7-36de348b9bbf.png"
            width={2704}
          />

          <Text>
            Select the "Automatic" Provisioning Mode from the dropdown menu.
          </Text>

          <Img
            className="my-8"
            height={1374}
            src="/images/b84aedad-4e43-41de-a9ec-b64e7c49443e.png"
            width={2040}
          />
          <Text>Copy and paste the endpoint into the "Tenant URL" field.</Text>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Text>
            Copy and paste the Bearer Token into the Secret Token field.
          </Text>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearerToken}
          />

          <Text>
            Click "Test Connection" to receive confirmation that your connection
            has been set up correctly. Then, select "Save" to persist the
            credentials.
          </Text>

          <Confirm
            label="I've configured the Admin Credentials."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up and enable attribute mapping',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>Expand the "Mappings" section.</Text>

          <Img
            className="my-8"
            height={1620}
            src="/images/8fff45f7-d023-4326-87c4-dba884ade3fb.png"
            width={2000}
          />

          <Text>
            Make sure the group and user attribute mappings are enabled, and are
            mapping the correct fields. The default mapping should work, but
            your specific Azure setup may require you to add a custom mapping.
          </Text>

          <Img
            className="my-8"
            height={1386}
            src="/images/630c1cda-e0a0-4705-90e6-2d026e715272.png"
            width={1976}
          />

          <Text>
            Make sure that you are mapping "objectId" to "externalId" within the
            Attribute Mapping section.
          </Text>

          <Img
            className="my-8"
            height={2154}
            src="/images/2018fe37-eabe-48c7-bfc3-c816e8f8b91c.png"
            width={2878}
          />

          <Confirm
            label="I've configured the attribute mappings."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up application attribute mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <>
          <Text>
            In addition to configuring attribute mappings in Azure, you also
            need to map these additional attributes the application is
            expecting.
          </Text>

          <Text>
            To see the names of attributes that you can map from your Directory
            Provider, return to the "Provisioning" tab in your Azure SCIM
            application and navigate to the Provisioning page.
          </Text>

          <Img
            priority
            height={1200}
            src="/admin-portal/directory-sync/azure/v1/azure-15.png"
            width={2820}
          />

          <Text>
            To view the list of available attributes to map for Users, click on
            the "Provision Azure Active Directory Users" button. This will bring
            you to the page that lists all available user attributes Azure
            provides.
          </Text>

          <Text>
            For example, if you wanted to use the value of "jobTitle", you would
            enter "title" in the Directory Provider Value field.
          </Text>

          <Img
            priority
            height={1376}
            src="/admin-portal/directory-sync/azure/v1/azure-16.png"
            width={1720}
          />

          <MapAttributesStep {...directoryStepProps} />
        </>
      ),
    },
    {
      name: 'Assign People & Groups to Azure AD SCIM Application',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            In order for your users and groups to be synced to
            {appName} you will need to assign them to your Azure AD SCIM
            Application. Select "Users and groups" from the "Manage" section of
            the navigation menu.
          </Text>

          <Img
            className="my-8"
            height={1334}
            src="/images/0c730d3b-baa7-4e9b-9a52-558096b5682c.png"
            width={2878}
          />

          <Text>Select "Add user/group" from the top menu.</Text>

          <Img
            className="my-8"
            height={1314}
            src="/images/ee49e2ce-a4dd-414d-9737-cad387369dfa.png"
            width={2878}
          />

          <Text>
            Select "None selected" under the "Users and Groups". In the menu,
            select the users and groups that you want to add to the SCIM
            application, and click "Select".
          </Text>

          <Img
            className="my-8"
            height={1314}
            src="/images/818e42eb-fc17-4722-98bd-fed201ed055b.png"
            width={2878}
          />

          <Text>
            Select "Assign" to add the selected users and groups to your SCIM
            application.
          </Text>

          <Img
            className="my-8"
            height={1094}
            src="/images/4d34de41-9842-46cb-bde0-5d990a5c97f4.png"
            width={2114}
          />

          <Text>
            Confirm the "Provisioning Status" is set to "On" and that the
            "Scope" is set to "Sync only assigned users and groups".
          </Text>

          <Img
            className="my-8"
            height={1656}
            src="/images/3f085d2a-66fb-4f40-bb6d-a7b1862ac79e.png"
            width={2426}
          />

          <Confirm
            label="I’ve completed my assignments and turned on provisioning."
            onClick={handleNextStep}
          />
        </>
      ),
    },
  ],
};
