import { DirectoryType } from '../graphql/generated';

export const URL_ERROR_MESSAGE = 'You must enter a valid url';

export enum EventName {
  DirectoryProviderSwitched = 'Admin Portal - Directory Provider Switched',
  IdentityProviderSwitched = 'Admin Portal - Identity Provider Switched',
  RecentActivityViewed = 'Admin Portal - Recent Activity Viewed',
  SSOStepCompleted = 'Admin Portal - SSO Step Completed',
}

export const DirectoryProviderLabels = {
  [DirectoryType.GenericScimv1_1]: 'Generic 1.1',
  [DirectoryType.GenericScimv2_0]: 'Generic 2.0',
};
