import React, { FC } from 'react';
import { ConnectionType } from '../../graphql/generated';
import { AttributeMapping } from './attribute-mapping';
import { attributeMappingConfig } from './attribute-mapping-config';

type Props = {
  connectionType?: ConnectionType;
};

export const ConnectionAttributeMapping: FC<Props> = ({ connectionType }) => {
  if (!connectionType) {
    return null;
  }
  const props = attributeMappingConfig[connectionType];
  if (props) {
    return (
      <AttributeMapping
        attributeGroups={props.attributeGroups}
        labels={props.labels}
      />
    );
  }
  return null;
};
