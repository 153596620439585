import { Text } from '@workos-inc/component-library';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { FC, ReactElement } from 'react';
import { Check, X } from 'react-feather';
import { SupportedConnectionType } from '../../interfaces/supported-connection-type';
import { SupportedDirectoryType } from '../../interfaces/supported-directory-type';

type StepsNavProps = {
  onChangeStep: (step: number) => void;
  steps: Step[];
  stepsWithError: number[];
  provider: SupportedConnectionType | SupportedDirectoryType | undefined;
};

export const StepsNav: FC<Readonly<StepsNavProps>> = ({
  onChangeStep,
  steps,
  stepsWithError,
  provider,
}) => {
  const confirmationStep = steps.length;
  const { query } = useRouter();
  const stepsCompleted = Number(query.step ?? 1) - 1;

  const getStepIcon = (index: number): ReactElement | null => {
    const isCompleted = index < stepsCompleted;
    const hasError = stepsWithError.includes(index + 1);

    if (hasError) {
      return (
        <Icon className="bg-red-200 text-red-darken">
          <X size={10} strokeWidth={4} />
        </Icon>
      );
    }

    if (index + 1 === confirmationStep && stepsWithError.length > 0) {
      // don't render a green check for confirmation step if we any errors
      return null;
    }

    if (isCompleted) {
      return (
        <Icon className="bg-green-100 text-green-600">
          <Check size={10} strokeWidth={4} />
        </Icon>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col">
      <Text inheritColor className="mb-6" size="large" weight="medium">
        Steps
      </Text>

      <motion.ol
        animate="show"
        className="ml-4"
        initial="hidden"
        variants={listVariants}
      >
        {steps.map((step: Step, index: number) => {
          const isActive = index + 1 === Number(query.step);
          const isClickable = index <= stepsCompleted;

          return (
            <motion.li
              key={`${provider}-${step.name}`}
              className={classNames(
                {
                  'list-none -ml-5 text-gray-lightmode-300':
                    !isActive && isClickable,
                  'list-decimal text-primary': isActive && isClickable,
                  'list-decimal text-gray-lightmode-200': !isClickable,
                },
                'mb-3 align-middle text-sm',
              )}
              variants={itemVariants}
            >
              <div
                className={classNames({
                  'cursor-pointer': isClickable,
                  'cursor-auto': !isClickable,
                })}
                onClick={() => isClickable && onChangeStep(index + 1)}
              >
                <Text inheritColor as="p">
                  {getStepIcon(index)}
                  {step.name}
                </Text>
              </div>
            </motion.li>
          );
        })}
      </motion.ol>
    </div>
  );
};

export type Step = {
  name: string;
};

type IconProps = {
  className?: string;
};

const Icon: FC<Readonly<IconProps>> = ({ children, className }) => (
  <i
    className={classNames(
      'rounded-full w-4 h-4 inline-flex justify-center items-center leading-4 text-center mr-2',
      className,
    )}
  >
    {children}
  </i>
);

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  show: { opacity: 1, x: 0 },
};
