import { unreachable } from '@workos-inc/standard';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../../interfaces/step-props';
import { Card } from '../../../card';
import { FileField } from '../../../fields';
import { Form } from '../../../form';
import { ManualMetadataStep } from './manual-metadata-step';

export interface MetadataXmlStepProps extends ConnectionStepProps {
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const MetadataXmlStep: FC<MetadataXmlStepProps> = ({
  children,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  ...connectionStepProps
}) => {
  const {
    connectionUpdatedFields,
    errors,
    isLoading,
    onNextStep: handleNextStep,
    setConnectionUpdatedFields,
    selectedConfiguration,
    setSelectedConfiguration,
  } = connectionStepProps;

  switch (selectedConfiguration) {
    case 'dynamic':
      return (
        <>
          {children}

          <Card>
            <Form
              disabled={!connectionUpdatedFields?.saml_idp_metadata_xml}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_metadata_xml}
              onSubmit={handleNextStep}
              secondaryAction={() => setSelectedConfiguration('manual')}
              secondaryText="Configure Manually"
            >
              <FileField
                accept=".xml"
                error={errors?.saml_idp_metadata_xml}
                label="XML Metadata File"
                name="saml_idp_metadata_xml"
                onUpload={({ file }) => {
                  setConnectionUpdatedFields({
                    saml_idp_metadata_xml: file.content,
                  });
                }}
                value={connectionUpdatedFields?.saml_idp_metadata_xml}
              />
            </Form>
          </Card>
        </>
      );
    case 'manual':
      return (
        <ManualMetadataStep
          {...connectionStepProps}
          idpEntityIdPlaceholder={idpEntityIdPlaceholder}
          idpSsoUrlPlaceholder={idpSsoUrlPlaceholder}
          onConfigurationTypeChange={(selectedConfiguration) =>
            setSelectedConfiguration(selectedConfiguration)
          }
        />
      );
    default:
      return unreachable(selectedConfiguration);
  }
};
