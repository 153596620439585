import { InputGroup, Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { Card } from '../../card';
import { Confirm } from '../../confirm';
import { FileField } from '../../fields';
import { Form } from '../../form';
import { Img } from '../../image';
import { CopyInput } from '../../input';

export const OneLoginOACSelectApplication: FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep: handleNextStep }) => (
  <>
    <Text>
      Log in to the OneLogin Admin Dashboard and select "Applications" in the
      navigation bar.
    </Text>

    <Img
      className="my-8"
      height={1142}
      src="/images/3eb4dd3a-b114-4af0-b8fb-bde51324b739.png"
      width={2250}
    />

    <Text>Click "Add App" on the Applications page.</Text>

    <Img
      className="my-8"
      height={1142}
      src="/images/39fb7a8d-8dc0-4aca-8883-90bdb2bdaa26.png"
      width={2250}
    />

    <Text>
      Search and select the app that you're connecting with from the App list.
    </Text>

    <Img
      className="my-8"
      height={1142}
      src="/images/9934f2f4-b7c6-455f-9ea7-cdfa4e5e214c.png"
      width={2250}
    />

    <Text>
      Pick a descriptive name for your app, such as {appName}, then click "Save"
      to complete adding the app to your application list.
    </Text>

    <Img
      className="my-8"
      height={1452}
      src="/images/643fcfc2-ddfb-4c73-b288-c2c1412a101a.png"
      width={2250}
    />

    <Confirm
      label="I’ve selected a SAML Enterprise Application"
      onClick={handleNextStep}
    />
  </>
);

export const OneLoginOACConfigureCustomerId: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>Click the “Configuration” tab from the left sidebar.</Text>

    <CopyInput
      label="Copy the unique ID from this ACS URL"
      value={connection?.saml_acs_url}
    />

    <Text>
      Submit the “Customer ID” in the Application details and click "Save".
    </Text>

    <Text>
      Paste only the customer ID which is the customer specific string at the
      end of the ACS URL.
    </Text>

    <Img
      className="my-8"
      height={910}
      src="/images/3d96cce5-b546-49bc-9ae7-1da21faa3977.png"
      width={2250}
    />

    <Confirm
      label="I’ve completed the Customer ID Configuration."
      onClick={handleNextStep}
    />
  </>
);

export const OneLoginOACUploadCertificate: FC<
  Readonly<ConnectionStepProps>
> = ({
  connectionUpdatedFields,
  setConnectionUpdatedFields,
  errors,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the “SSO” tab from the left sidebar.</Text>

    <Img
      className="my-8"
      height={1570}
      src="/images/6e21cb8d-5fea-4f4a-9514-aca666d8b52f.png"
      width={2250}
    />

    <Text>
      Click “View Details” under the X.509 Certificate. This will open a
      certificate configuration page.
    </Text>

    <Text>Download the X.509 Certificate and upload it below.</Text>

    <Img
      className="my-8"
      height={1598}
      src="/images/b813c800-4632-4a12-a13a-4384073aec8e.png"
      width={2250}
    />

    <Card>
      <Form
        disabled={!connectionUpdatedFields?.samlX509Certificates}
        isUpdate={!!errors?.samlX509Certificates}
        onSubmit={handleNextStep}
      >
        <FileField
          error={errors?.samlX509Certificates}
          label="X.509 Certificate"
          name="saml_x509_certs"
          onUpload={({ file }) => {
            setConnectionUpdatedFields({
              samlX509Certificates: [file.content],
            });
          }}
          value={connectionUpdatedFields?.samlX509Certificates?.[0]}
        />
      </Form>
    </Card>
  </>
);

export const OneLoginOACProvideEndpoint: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  errors,
  isLoading,
  onInputChange: handleInputChange,
  onNextStep: handleNextStep,
  validationErrors,
}) => (
  <>
    <Text>
      Navigate back to the “SSO” tab in your Application and copy the SAML 2.0
      Endpoint.
    </Text>

    <Img
      className="my-8"
      height={1558}
      src="/images/737422b0-3fe3-460c-a8a8-d283edfeaea2.png"
      width={2250}
    />

    <Card>
      <Form
        disabled={!connection?.saml_idp_url}
        isLoading={isLoading}
        onSubmit={handleNextStep}
      >
        <InputGroup
          autoFocus
          error={
            errors?.saml_idp_url?.message ||
            validationErrors?.saml_idp_url?.message
          }
          id="saml_idp_url"
          label="SAML 2.0 Endpoint"
          name="saml_idp_url"
          onChange={handleInputChange}
          placeholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
          value={connection?.saml_idp_url ?? undefined}
        />
      </Form>
    </Card>
  </>
);
