import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataUrlStep } from './saml/metadata-url-step';

export const CyberArkCreateIntegration: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Log in to the CyberArk Identity Admin Portal and select "Web Apps" from
      the left-side navigation.
    </Text>

    <Img
      className="my-8"
      height={1688}
      src="/images/0b6cc08c-4396-4690-b6ec-bfdd19482407.png"
      width={2946}
    />

    <Text>Select "Add Web Apps" to begin creating a new SAML application.</Text>

    <Img
      className="my-8"
      height={1688}
      src="/images/dbdefe19-2a7e-40fe-9946-d72e14fc5a0e.png"
      width={2946}
    />

    <Text>Select the "Custom" tab and then click to add "SAML".</Text>

    <Img
      className="my-8"
      height={813}
      src="/images/a3f19885-982a-4909-bc4b-7171dc91379d.png"
      width={1440}
    />

    <Text>Select "Yes" to begin setting up the SAML App.</Text>

    <Img
      className="my-8"
      height={813}
      src="/images/26b4f311-11c6-492d-a047-8b5cb7208473.png"
      width={1440}
    />

    <Text>
      Enter a descriptive App Name and description, then click "Save".
    </Text>

    <Img
      className="my-8"
      height={883}
      src="/images/fbd41606-77cf-4183-91f6-d779b95c073c.png"
      width={1461}
    />

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      On the "Trust" tab of the SAML App, go to the "Service Provider
      Configuration Section" and select "Manual Configuration". Input the ACS
      URL above as both the "SP Entity Id / Issuer / Audience" and "Assertion
      Consumer Service (ACS) URL".
    </Text>

    <Text>
      IMPORTANT: Be sure to check "Both" under "Sign Response or Assertion?"
    </Text>

    <Img
      className="my-8"
      height={812}
      src="/images/c6fa4b05-b31d-4ae5-9d5b-6ee8a7d01fa9.png"
      width={1451}
    />

    <Confirm
      label="I’ve configured the SP Entity ID / Issuer / Audience and ACS URL."
      onClick={handleNextStep}
    />
  </>
);

export const CyberArkConfigureAttributeStatements: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      Select the "SAML Response" tab and use the "Add" button to add the
      following key-value pairs. Then, click "Save".
    </Text>

    <Img
      className="my-8"
      height={812}
      src="/images/dfa45599-be42-4201-a6b7-26e94b4dc23e.png"
      width={1451}
    />

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I’ve finished configuring the Attribute Statements
          and clicked “Save”."
      onClick={handleNextStep}
    />
  </>
);

export const CyberArkAddUsers: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      To give users permission to authenticate via this SAML app, you will need
      to assign individual users and/or groups of users to the CyberArk SAML
      app.
    </Text>

    <Text>Click on the "Permissions" tab, and select "Add"</Text>

    <Img
      className="my-8"
      height={814}
      src="/images/050f6bfb-6cfe-4a73-a282-6a46d89dc633.png"
      width={1423}
    />

    <Text>
      Search for the individual user(s) and/or group(s) that you would like to
      assign to the app, and check the box next to them. Click "Add" when you
      are finished. Once users have been successfully added, you should also
      notice the "Status" of your CyberArk SAML app change to "Deployed".
    </Text>

    <Img
      className="my-8"
      height={814}
      src="/images/236bc4af-b19c-4598-a9db-bae816be1f2c.png"
      width={1423}
    />

    <Confirm
      label="I’ve users and/or groups to the SAML app."
      onClick={handleNextStep}
    />
  </>
);

export const CyberArkUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="http://www.cyberark.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.cyberark.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://cyberark-metadata-url"
  >
    <Text>
      You can find the Metadata URL under the "Trust" tab of the SAML app in the
      "Identity Provider Configuration" section. Enter the value into the field
      below.
    </Text>

    <Img
      className="my-8"
      height={814}
      src="/images/dd386d12-5aaa-4446-9a91-6a047fc4e200.png"
      width={1423}
    />
  </MetadataUrlStep>
);
