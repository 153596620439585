import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataUrlStep } from './saml/metadata-url-step';

export const PingOneCreateIntegration: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      On the "Applications" tab, select "My Applications". Click on "Add
      Application" and select "New SAML Application"
    </Text>

    <Img
      height={1732}
      src="/images/09723c34-9371-4916-8c4e-a90d60b6d09d.png"
      width={2270}
    />

    <Text>
      Give it a descriptive "Application Name" to easily identify it later, such
      as {appName}-{connection?.name}. Then fill out the "Application
      Description" and select a "Category".
    </Text>

    <Img
      height={1732}
      src="/images/55f075db-7930-41c8-8f27-e6f8e8a44899.png"
      width={2270}
    />

    <Text>Click "Continue to Next Step".</Text>

    <Confirm
      label={
        'I\'ve finished creating the SAML Integration and clicked "Continue to Next Step".'
      }
      onClick={handleNextStep}
    />
  </>
);

export const PingOneSubmitAcsUrl: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>
      Submit ACS URL as the "Assertion Consumer Service (ACS)" and the Entity ID
      as the "Entity ID".
    </Text>

    <Img
      height={1938}
      src="/images/66903d7d-0206-4a8f-b880-83ba1a020f80.png"
      width={2146}
    />

    <Text>Click "Continue to Next Step".</Text>

    <Confirm
      label={
        'I\'ve configured the Assertion Consumer Service (ACS) and Entity ID and clicked "Continue to Next Step".'
      }
      onClick={handleNextStep}
    />
  </>
);

export const PingOneConfigureAttributeStatements: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      On the "SSO Attribute Mapping" step, click on the "Add new attribute"
      button to add new attributes to map.
    </Text>

    <Img
      height={1392}
      src="/images/3aa894ca-786f-47a5-b019-6ddbae47b0f6.png"
      width={2056}
    />

    <Text>
      Fill in the following Attribute Statements and click “Continue to Next
      Step”:
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label={
        'I\'ve finished configuring the Attribute Statements and clicked "Continue to Next Step".'
      }
      onClick={handleNextStep}
    />
  </>
);

export const PingOneUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
    idpSsoUrlPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
    metadataUrlPlaceholder="https://admin-api.pingone.com/latest/metadata/..."
  >
    <Text>
      At the end of the Application Setup Wizard, you'll be given an overview of
      your Identity Provider metadata.
    </Text>

    <Img
      height={1828}
      src="/images/b6b0ae64-d298-449a-85f7-ab7f896945c9.png"
      width={2148}
    />

    <Text>Copy and paste the "SAML Metadata URL" in the field below.</Text>
  </MetadataUrlStep>
);
